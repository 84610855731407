@layer modules, ui, base;
@layer ui {
  /** @define Icon */

.Icon_jds-Icon__qGb6e {
  fill: currentcolor;
  vertical-align: middle;
}

.Icon_jds-Icon--tiny__eJMHC {
  width: var(--icon-size-tiny);
  min-width: var(--icon-size-tiny);
  height: var(--icon-size-tiny);
}

.Icon_jds-Icon--small__TQ5up {
  width: var(--icon-size-small);
  min-width: var(--icon-size-small);
  height: var(--icon-size-small);
}

.Icon_jds-Icon--medium__CgyeE {
  width: var(--icon-size-medium);
  min-width: var(--icon-size-medium);
  height: var(--icon-size-medium);
}

.Icon_jds-Icon--big__bzeG_ {
  width: var(--icon-size-big);
  min-width: var(--icon-size-big);
  height: var(--icon-size-big);
}

.Icon_jds-Icon--large__KNPQa {
  width: var(--icon-size-large);
  min-width: var(--icon-size-large);
  height: var(--icon-size-large);
}

.Icon_jds-Icon--huge__GF_BE {
  width: var(--icon-size-huge);
  min-width: var(--icon-size-huge);
  height: var(--icon-size-huge);
}

}
@layer ui {
  /** @define Spinner */

.Spinner_jds-Spinner__qSvCy {
  width: 1.25rem;
  height: 1.25rem;
  animation: Spinner_spinner__z_cy6 2s linear infinite;
  stroke-width: 0.6rem;
  color: currentcolor;
  vertical-align: middle;
}

.Spinner_jds-Spinner--minor__v4Zv_ {
  width: 1rem;
  height: 1rem;
}

.Spinner_jds-Spinner--ghost__g_jYG {
  color: var(--color-black);
}

@keyframes Spinner_spinner__z_cy6 {
  50% {
    transform: rotate(360deg);
    stroke-dashoffset: 20;
  }

  100% {
    transform: rotate(1080deg);
    stroke-dashoffset: 243.4675;
  }
}

}
@layer ui {
  /** @define Button */

.button_jds-Button__Cbo3A {
  --Button-border-width: 2px;
  --Button-horizontaly-padding: calc(16px - var(--Button-border-width));
  --Button-verticaly-padding: calc(12px - var(--Button-border-width));
  --Button-minor-horizontaly-padding: calc(16px - var(--Button-border-width));
  --Button-minor-verticaly-padding: calc(10px - var(--Button-border-width));
  --Button-icon-only: 10px;
  --Button-icon-only-minor: 10px;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  padding: var(--Button-verticaly-padding) var(--Button-horizontaly-padding);
  border: var(--Button-border-width) solid var(--themeMainColor, var(--mainColor));
  border-radius: var(--radius-200);
  background-color: var(--themeMainColor, var(--mainColor));
  color: var(--themeButtonTextColor, var(--color-black));
  font-family: inherit;
  font-size: var(--normalBody);
  font-weight: var(--semiBold);
  line-height: var(--normalBodyLineHeight);
  text-align: center;
  text-decoration: none;
  text-shadow: none;
  cursor: pointer;
}

.button_jds-Button__Cbo3A:focus:not(.button_jds-Button--onlyIcon__s_xZR) {
  outline: none;
}

.button_jds-Button__Cbo3A:hover {
  border-color: var(--themeMainDarkenColor, var(--color-green--550));
  background-color: var(--themeMainDarkenColor, var(--color-green--550));
}

.button_jds-Button__Cbo3A:active {
  border-color: var(--themeMainDarkenColor, var(--color-green--600));
  background-color: var(--themeMainDarkenColor, var(--color-green--600));
}

.button_jds-Button__Cbo3A:focus {
  border-width: var(--Button-border-width);
  border-color: var(--color-black);
  outline: none;
  background-color: var(--themeMainDarkenColor, var(--color-green--550));
}

/*******************
Secondary
*******************/
.button_jds-Button--ghost__lXkuc {
  border-color: var(--color-black);
  background-color: transparent;
  color: var(--color-black);
}

.button_jds-Button--ghost__lXkuc:hover { background-color: var(--color-grey--050); }
.button_jds-Button--ghost__lXkuc:focus { background-color: var(--color-grey--100); }
.button_jds-Button--ghost__lXkuc:active { background-color: var(--color-grey--200); }

.button_jds-Button--ghost__lXkuc:hover,
.button_jds-Button--ghost__lXkuc:focus,
.button_jds-Button--ghost__lXkuc:active { border-color: var(--color-black); }

.button_jds-Button--ghostInverted__wQ68N {
  border-color: var(--color-grey--800);
  background-color: var(--color-grey--800);
  color: var(--color-grey--025);
}

.button_jds-Button--ghostInverted__wQ68N:hover {
  background-color: var(--color-grey--500);
}

.button_jds-Button--ghostInverted__wQ68N:hover,
.button_jds-Button--ghostInverted__wQ68N:focus {
  border-color: var(--color-grey--900);
  outline: none;
  background-color: var(--color-grey--900);
}

.button_jds-Button--ghostInverted__wQ68N:active {
  border-color: var(--color-grey--950);
  background-color: var(--color-grey--950);
}

/************************
  Tertiary
*************************/
.button_jds-Button--naked__wn4BF {
  border-color: transparent;
  background-color: transparent;
  color: var(--color-grey--900);
}

.button_jds-Button--naked__wn4BF:hover {
  border-color: transparent;
  background-color: var(--color-grey--100);
}

.button_jds-Button--naked__wn4BF:active {
  border-color: transparent;
  background-color: var(--color-grey--200);
}

.button_jds-Button--naked__wn4BF:focus {
  border-color: var(--color-grey--800);
  background-color: var(--color-grey--100);
}

/*******************
Status
*******************/
.button_jds-Button--error__y8_pk {
  border-color: var(--color-error--500);
  background-color: var(--color-error--500);
  color: var(--color-white);
}

.button_jds-Button--error__y8_pk:hover,
.button_jds-Button--error__y8_pk:focus {
  border-color: var(--color-error--550);
  background-color: var(--color-error--550);
}

.button_jds-Button--error__y8_pk:active {
  border-color: var(--color-error--600);
  background-color: var(--color-error--600);
}

.button_jds-Button--error__y8_pk:focus {
  border-color: var(--color-error--700);
}

.button_jds-Button--success__HiDUf {
  border-color: var(--color-success--500);
  background-color: var(--color-success--500);
  color: var(--color-white);
}

.button_jds-Button--success__HiDUf:hover,
.button_jds-Button--success__HiDUf:focus {
  border-color: var(--color-success--550);
  background-color: var(--color-success--550);
}

.button_jds-Button--success__HiDUf:active {
  border-color: var(--color-success--600);
  background-color: var(--color-success--600);
}

.button_jds-Button--success__HiDUf:focus {
  border-color: var(--color-black);
}

/************************************
  Loading Button / Animated Spinner
*************************************/

.button_jds-Button--loading__4ATJU {
  cursor: progress;
}

.button_jds-Button__spinnerHiddenContent__QRjEC {
  display: inline-flex;
  visibility: hidden;
  align-items: center;
}

.button_jds-Button__spinner__ESH_A {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* stylelint-disable-next-line plugin/selector-bem-pattern */
.button_jds-Button--ghost__lXkuc.button_jds-Button--loading__4ATJU {
  border-color: var(--color-black);
  background-color: var(--color-grey--025);
  color: var(--color-black);
}

/**********************************
  Button with icon
***********************************/
.button_jds-Button--withIcon__m_JDp {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.button_jds-Button__icon__ZHjCZ {
  box-sizing: content-box;
  flex-basis: auto;
  padding-right: var(--spacer-100);
}

.button_jds-Button--withIconOnRight__Hu8Cy {
  flex-direction: row-reverse;
}

.button_jds-Button--withIconOnRight__Hu8Cy .button_jds-Button__icon__ZHjCZ {
  padding: 0 0 0 var(--spacer-100);
}

/**********************************
  Button with only icon
***********************************/
.button_jds-Button--onlyIcon__s_xZR {
  display: inline-flex;
  padding: var(--Button-icon-only);
}

.button_jds-Button--onlyIcon__s_xZR .button_jds-Button__icon__ZHjCZ {
  padding: 0;
}

.button_jds-Button--onlyIcon__s_xZR:not(.button_jds-Button--minor__ukW_c) .button_jds-Button__icon__ZHjCZ {
  width: var(--icon-size-big);
  height: var(--icon-size-big);
}

/* stylelint-disable-next-line plugin/selector-bem-pattern */
.button_jds-Button--onlyIcon__s_xZR.button_jds-Button--minor__ukW_c {
  padding: var(--Button-icon-only-minor);
}

/**********************************
  Button disabled
***********************************/
.button_jds-Button--disabled___axeq,
.button_jds-Button--disabled___axeq:hover {
  border-color: var(--color-grey--200);
  background-color: var(--color-grey--200);
  color: var(--color-grey--400);
  cursor: not-allowed;
}

/* stylelint-disable plugin/selector-bem-pattern */
.button_jds-Button--ghost__lXkuc.button_jds-Button--disabled___axeq {
  border-color: var(--color-grey--300);
  background-color: var(--color-white);
  color: var(--color-grey--300);
}

.button_jds-Button--ghost__lXkuc.button_jds-Button--disabled___axeq:hover {
  background-color: transparent;
}

.button_jds-Button--disabled___axeq.button_jds-Button--ghostInverted__wQ68N,
.button_jds-Button--disabled___axeq.button_jds-Button--ghostInverted__wQ68N:hover {
  border-color: var(--color-grey--050);
  background-color: var(--color-grey--050);
  color: var(--color-grey--300);
}

.button_jds-Button--naked__wn4BF.button_jds-Button--disabled___axeq,
.button_jds-Button--naked__wn4BF.button_jds-Button--disabled___axeq:hover {
  border-color: transparent;
  background-color: transparent;
  color: var(--color-grey--300);
}

.button_jds-Button--error__y8_pk.button_jds-Button--disabled___axeq {
  background-color: var(--color-error--100);
  color: var(--color-grey--200);
}

.button_jds-Button--success__HiDUf.button_jds-Button--disabled___axeq {
  background-color: var(--color-success--100);
  color: var(--color-grey--200);
}
/* stylelint-enable */

/******************************
  Loading and disabled Button
*******************************/

.button_jds-Button--loading__4ATJU[disabled] {
  cursor: wait;
}

/******************************
  Minor button
*******************************/
.button_jds-Button--minor__ukW_c {
  padding: var(--Button-minor-verticaly-padding) var(--Button-minor-horizontaly-padding);
  font-size: var(--body2);
  line-height: var(--body2-lh);
}

/******************************
  Full width button
*******************************/

.button_jds-Button--fullWidth__MTiwc {
  display: inline-flex;
  justify-content: center;
  width: 100%;
  text-align: left;
}

}
@layer ui {
  /** @define Text */

.text_jds-Text__15y8h {
  color: inherit;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
}

.text_jds-Text--normal__VuON_,
.text_jds-Text--edito__aBPHN {
  font-size: var(--body1);
  line-height: var(--body1-lh);
}

.text_jds-Text--small__cqvDw,
.text_jds-Text--label__NchAR {
  font-size: var(--body2);
  line-height: var(--body2-lh);
}

.text_jds-Text--label__NchAR {
  font-weight: var(--semiBold);
  text-transform: uppercase;
}

.text_jds-Text--minor__jABjP,
.text_jds-Text--caption__6nX_h {
  font-size: var(--caption1);
  line-height: var(--caption-lh);
}

.text_jds-Text--heading-extra___Yr_d,
.text_jds-Text--headingBig__eT9R6,
.text_jds-Text--heading-big__oPKWH,
.text_jds-Text--heading__xKbad,
.text_jds-Text--heading-small__0_01a {
  font-family: var(--fontHeading);
  font-weight: var(--bold);
}

.text_jds-Text--heading-extra___Yr_d {
  font-size: var(--display1);
  line-height: var(--display1-lh);
}

.text_jds-Text--headingBig__eT9R6,
.text_jds-Text--heading-big__oPKWH {
  font-size: var(--display2);
  line-height: var(--display2-lh);
}

.text_jds-Text--heading__xKbad {
  font-size: var(--title2);
  line-height: var(--title2-lh);
}

.text_jds-Text--heading-small__0_01a {
  font-size: var(--title3);
  line-height: var(--title3-lh);
}

.text_jds-Text--subhead___TgRV,
.text_jds-Text--subhead-small__5bkWB {
  font-size: var(--title4);
  line-height: var(--title4-lh);
}

.text_jds-Text--caps__D8GoJ {
  text-transform: uppercase;
}

.text_jds-Text--italic__GIuFn {
  font-style: italic;
}

.text_jds-Text--uppercase__hEvTJ {
  text-transform: uppercase;
}

.text_jds-Text--resetSpacing__gNair {
  margin: 0;
  padding: 0;
}

.text_jds-Text--left__IicTW {
  text-align: left;
}

.text_jds-Text--center__d0pko {
  text-align: center;
}

.text_jds-Text--right__hmj0F {
  text-align: right;
}

.text_jds-Text--black__jQhPm {
  color: var(--color-black);
}

.text_jds-Text--white__PkoKT {
  color: var(--color-white);
}

.text_jds-Text--grey__aKOUp {
  color: var(--color-grey--600);
}

.text_jds-Text--greyLight__gZE4a {
  color: var(--color-grey--200);
}

.text_jds-Text--mainColor__A2FdC {
  color: var(--color-black);
}

.text_jds-Text--secondColor__ngyVn {
  color: var(--color-blue);
}

.text_jds-Text--success__l9EGG {
  color: var(--color-success--500);
}

.text_jds-Text--warning__UGG2C {
  color: var(--color-warning--500);
}

.text_jds-Text--error__rkigS {
  color: var(--color-error--500);
}

.text_jds-Text--weight-light__Ob2Dc {
  font-weight: var(--light);
}

.text_jds-Text--weight-normal__uo6Vk {
  font-weight: var(--normal);
}

.text_jds-Text--weight-medium__KxpPm {
  font-weight: var(--medium);
}

.text_jds-Text--weight-semi-bold__eF41S {
  font-weight: var(--semiBold);
}

.text_jds-Text--weight-bold__KNxoa {
  font-weight: var(--bold);
}

.text_jds-Text--weight-extra-bold__o56Nu {
  font-weight: var(--extraBold);
}

.text_jds-Text--weight-black__AYmOH {
  font-weight: var(--black);
}

@media (min-width: 48rem) {
  .text_jds-Text--heading__xKbad {
    font-size: var(--title1);
    line-height: var(--title1-lh);
  }

  .text_jds-Text--heading-small__0_01a {
    font-size: var(--title2);
    line-height: var(--title2-lh);
  }

  .text_jds-Text--subhead___TgRV,
  .text_jds-Text--subhead-small__5bkWB {
    font-size: var(--title3);
    line-height: var(--title3-lh);
  }
}

}
@layer ui {
  /* @define Dropdown */
.dropdown_jds-Dropdown__container__PvV0i {
  display: inline-flex;
  position: relative;
}

.dropdown_jds-Dropdown__menu__RMBJx {
  display: flex;
  visibility: hidden;
  position: absolute;
  z-index: 2;
  flex-direction: column;
  width: -moz-max-content;
  width: max-content;
  /*
    MIN must be uppercase to avoid an error when precompiling SASS assets
    https://stackoverflow.com/questions/54090345/sass-incompatible-units-vw-and-px/68513299#68513299
  */
  /* stylelint-disable-next-line function-name-case */
  min-width: MAX(140px, 100%); /* minimal width is dropdown trigger width */
  /* stylelint-disable-next-line function-name-case */
  max-width: MIN(100vw, 500px);
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style-type: none;
  border-radius: var(--radius-200);
  background: var(--color-white);
  box-shadow: var(--default-shadow);
  font-size: var(--normalBody);
  text-overflow: ellipsis;
  pointer-events: none;
}

.dropdown_jds-Dropdown__item--selected__b_gnq {
  font-weight: var(--bold);
}

.dropdown_jds-Dropdown__menu--show__DGb7n {
  visibility: visible;
  pointer-events: initial;
}

.dropdown_jds-Dropdown__item__ZqykD {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: var(--spacer-150) var(--spacer-200);
  border: 0;
  background-color: transparent;
  color: var(--color-black);
  font-family: inherit;
  font-size: var(--normalBody);
  line-height: var(--normalBodyLineHeight);
  text-align: left;
  text-decoration: none;
}

.dropdown_jds-Dropdown__itemWrapper__weBdf:first-child .dropdown_jds-Dropdown__item__ZqykD {
  border-radius: var(--radius-200) var(--radius-200) 0 0;
}

.dropdown_jds-Dropdown__itemWrapper__weBdf:last-child .dropdown_jds-Dropdown__item__ZqykD {
  border-radius: 0 0 var(--radius-200) var(--radius-200);
}

.dropdown_jds-Dropdown__item__ZqykD:not([disabled]):hover {
  background-color: var(--color-grey--025);
  color: var(--black);
  cursor: pointer;
}

.dropdown_jds-Dropdown__item__ZqykD:not([disabled]):focus {
  outline: 1px solid var(--color-grey--500);
  outline-offset: -1px;
}

.dropdown_jds-Dropdown__item__ZqykD[disabled] {
  color: var(--color-grey--200);
}

}
@layer ui {
  /** @define MenuTag */

.MenuTag_jds-MenuTag__Rju_e {
  margin-right: var(--spacer-50);
  padding: 0 var(--spacer-50);
  border: 1px solid var(--color-grey--025);
  border-radius: var(--radius-200);
  background: var(--color-white);
  color: var(--color-magenta--500);
  font-size: var(--minorBody);
  font-weight: var(--bold);
  line-height: var(--minorBodyLineHeight);
  text-transform: uppercase;
}

}
@layer ui {
  /** @define MenuItem */

.MenuItem_jds-MenuItem__MRXWJ {
  display: inline-flex;
  position: relative;
  align-items: center;
  padding: 0 0 var(--spacer-200);
  border: none;
  outline: 0;
  background: none;
  color: var(--color-black);
  font-family: var(--fontFamily);
  font-size: var(--normalBody);
  line-height: var(--normalBodyLineHeight);
  text-decoration: none;
  cursor: pointer;
}

.MenuItem_jds-MenuItem__MRXWJ::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 4px;
  border-radius: var(--radius-200);
}

.MenuItem_jds-MenuItem__MRXWJ:not(.MenuItem_jds-MenuItem--selected__91sFZ):hover,
.MenuItem_jds-MenuItem__MRXWJ:not(.MenuItem_jds-MenuItem--selected__91sFZ):focus {
  border-color: var(--color-grey--200);
}

.MenuItem_jds-MenuItem--selected__91sFZ {
  border-color: var(--color-black);
  font-weight: var(--semiBold);
}

.MenuItem_jds-MenuItem--selected__91sFZ::after,
.MenuItem_jds-MenuItem__MRXWJ:hover::after {
  background-color: var(--color-black);
}

}
@layer ui {
  .sk-Link_main__nOUpr {
  --Link-external-icon-caption1-size: var(--sk-size-12);
  --Link-external-icon-body2-size: var(--sk-size-16);
  --Link-external-icon-body1-size: var(--sk-size-20);
  --Link-external-icon-size: var(--Link-external-icon-body2-size);
  --Link-external-icon-data: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Im0xNSA1Yy0uNTUyMyAwLTEtLjQ0NzcyLTEtMXMuNDQ3Ny0xIDEtMWg1Yy41NTIzIDAgMSAuNDQ3NzIgMSAxdjVjMCAuNTUyMjgtLjQ0NzcgMS0xIDFzLTEtLjQ0NzcyLTEtMXYtMi41ODU3OWwtOC4yOTI5IDguMjkyODljLS4zOTA1LjM5MDUtMS4wMjM2OC4zOTA1LTEuNDE0MjEgMC0uMzkwNTItLjM5MDUtLjM5MDUyLTEuMDIzNyAwLTEuNDE0Mmw4LjI5MjkxLTguMjkyOXptLTExLjEyMTMyIDEuODc4NjhjLjU2MjYxLS41NjI2MSAxLjMyNTY3LS44Nzg2OCAyLjEyMTMyLS44Nzg2OGg1Yy41NTIzIDAgMSAuNDQ3NzIgMSAxcy0uNDQ3NyAxLTEgMWgtNWMtLjI2NTIyIDAtLjUxOTU3LjEwNTM2LS43MDcxMS4yOTI4OS0uMTg3NTMuMTg3NTQtLjI5Mjg5LjQ0MTg5LS4yOTI4OS43MDcxMXY5YzAgLjI2NTIuMTA1MzYuNTE5Ni4yOTI4OS43MDcxLjE4NzU0LjE4NzUuNDQxODkuMjkyOS43MDcxMS4yOTI5aDljLjI2NTIgMCAuNTE5Ni0uMTA1NC43MDcxLS4yOTI5cy4yOTI5LS40NDE5LjI5MjktLjcwNzF2LTVjMC0uNTUyMy40NDc3LTEgMS0xczEgLjQ0NzcgMSAxdjVjMCAuNzk1Ny0uMzE2MSAxLjU1ODctLjg3ODcgMi4xMjEzcy0xLjMyNTYuODc4Ny0yLjEyMTMuODc4N2gtOWMtLjc5NTY1IDAtMS41NTg3MS0uMzE2MS0yLjEyMTMyLS44Nzg3cy0uODc4NjgtMS4zMjU3LS44Nzg2OC0yLjEyMTN2LTljMC0uNzk1NjUuMzE2MDctMS41NTg3MS44Nzg2OC0yLjEyMTMyeiIgZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+");

  display: inline;
  /* This property is required when Link is used inside flex parent. */
  width: -moz-fit-content;
  width: fit-content;
  border-radius: var(--sk-navigation-link-radius);
  outline: none;
  font-family: var(--sk-typography-navigation-link-text-typography-m-font-family), helvetica, arial, sans-serif;
  font-size: inherit;
  font-weight: var(--sk-typography-navigation-link-text-typography-m-font-weight);
  line-height: inherit;
  text-decoration: underline;
  cursor: pointer;
}

.sk-Link_main--external__hOR_4::after {
  content: "";
  display: inline-flex;
  width: var(--Link-external-icon-size);
  height: var(--Link-external-icon-size);
  margin-left: var(--sk-space-2);
  /* Adjust svg alignment with text */
  transform: translateY(-0.0625rem); /* -1px */
  /* Use the same color of the link for the external icon. */
  background-color: currentcolor;
  vertical-align: sub;
  /* Required by Sandpack as it uses css as is. */
  -webkit-mask-image: var(--Link-external-icon-data);
  mask-image: var(--Link-external-icon-data);
  -webkit-mask-size: cover;
  mask-size: cover;
}

.sk-Link_main__nOUpr:focus-visible {
  outline-offset: 0;
  outline-style: solid;
  outline-width: var(--sk-navigation-link-size-border-focus);
}

.sk-Link_main--caption1__YoyQD {
  font-size: var(--sk-typography-navigation-link-text-typography-s-font-size);
  font-weight: var(--sk-typography-navigation-link-text-typography-s-font-weight);
  line-height: var(--sk-typography-navigation-link-text-typography-s-line-height);
}

.sk-Link_main--caption1__YoyQD.sk-Link_main--external__hOR_4::after {
  --Link-external-icon-size: var(--Link-external-icon-caption1-size);

  line-height: var(--sk-typography-navigation-link-text-typography-s-line-height);
}

.sk-Link_main--body1__4qy4x {
  font-size: var(--sk-typography-navigation-link-text-typography-l-font-size);
  line-height: var(--sk-typography-navigation-link-text-typography-l-line-height);
}

.sk-Link_main--body1__4qy4x.sk-Link_main--external__hOR_4::after {
  --Link-external-icon-size: var(--Link-external-icon-body1-size);

  line-height: var(--sk-typography-navigation-link-text-typography-l-line-height);
}

.sk-Link_main--body2__5CxGA {
  font-size: var(--sk-typography-navigation-link-text-typography-m-font-size);
  line-height: var(--sk-typography-navigation-link-text-typography-m-line-height);
}

.sk-Link_main--body2__5CxGA.sk-Link_main--external__hOR_4::after {
  --Link-external-icon-size: var(--Link-external-icon-body2-size);

  line-height: var(--sk-typography-navigation-link-text-typography-m-line-height);
}

.sk-Link_main--light__sHPm0 {
  color: var(--sk-navigation-link-color-default-light);
}

.sk-Link_main--light__sHPm0:focus-visible {
  outline-color: var(--sk-navigation-link-color-border-focus-light);
}

.sk-Link_main--light__sHPm0:hover {
  background-color: var(--sk-navigation-link-color-background-hover-light);
  color: var(--sk-navigation-link-color-hover-light);
}

.sk-Link_main--dark__PTzF2 {
  color: var(--sk-navigation-link-color-default-dark);
}

.sk-Link_main--dark__PTzF2:focus-visible {
  outline-color: var(--sk-navigation-link-color-border-focus-dark);
}

.sk-Link_main--dark__PTzF2:hover {
  background-color: var(--sk-navigation-link-color-background-hover-dark);
  color: var(--sk-navigation-link-color-hover-dark);
}

}
@layer ui {
  /* @define LayoutColumn */

.layouColumn_jds-LayoutColumn__QufQy {
  box-sizing: border-box;
  width: 100%;
  /*
  "min-width: 0" is used to prevent the carousel component to overflow and give a too big size at the layout column
  We cannod use a simple "overflow-x: hidden" on the layout cause we need to display the arrows which have a light overflow on the layout column
  */
  min-width: 0;
  margin: var(--spacer-100) var(--Layout-column-margin-size);
}

.layouColumn_jds-LayoutColumn__QufQy:first-child {
  margin-left: var(--Layout-column-margin-size);
}

.layouColumn_jds-LayoutColumn__QufQy:last-child {
  margin-right: var(--Layout-column-margin-size);
}

@media (min-width: 48rem) {
  .layouColumn_jds-LayoutColumn__QufQy {
    flex: 1;
    margin: 0;
  }

  .layouColumn_jds-LayoutColumn--fixedWidth__JCI_8 {
    --LayoutColumn-FixedWidth: calc(var(--Layout-column-size) * var(--layout-column-number) + ( var(--layout-column-number) - 1 ) * var(--Layout-column-gutter-size));
    flex: 0 0 var(--LayoutColumn-FixedWidth);
  }
}

}
@layer ui {
  /** @define Layout */

.layout_jds-Layout__hFF0F {
  --Layout-columnsBase: 12;
  --Layout-size: 100%;
  --Layout-padding: calc((100% - var(--Layout-size)) / 2);
  --Layout-column-gutter-size: var(--spacer-200);
  --Layout-column-margin-size: 1.25rem; /* 20px (specific spacer for Layout) */
  --Layout-column-size: calc(( ( var(--Layout-size) - var(--Layout-column-margin-size) * 2 ) - var(--Layout-column-gutter-size) * ( var(--Layout-columnsBase) - 1) ) / var(--Layout-columnsBase));

  display: flex;
  box-sizing: content-box; /* Fix box sizing to override some style coming from legacy component */
  flex-wrap: wrap;
  width: var(--Layout-size);
  padding: 0 var(--Layout-padding);
}

.layout_jds-Layout--isBo__hWLcI {
  --Layout-size: calc(100% - var(--jds-bo-width-delta, 0px));
}

.layout_jds-Layout--isFullWidth__Lkbb3 {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
}

.layout_jds-Layout--topPadding__vMye5 {
  padding-top: var(--spacer-400);
}

.layout_jds-Layout--bottomPadding__sa1oV {
  padding-bottom: var(--spacer-400);
}

.layout_jds-Layout--greyBlock__k6wDa {
  background: var(--color-grey--025);
}

.layout_jds-Layout--blackBlock__kQo1N {
  background: var(--color-black);
  color: var(--color-white);
}

.layout_jds-Layout--whiteBlock__KxtWG {
  background: var(--color-white);
}

.layout_jds-Layout--separator__6Jcn_ {
  border-bottom: 1px solid var(--color-grey--200);
}

@media (min-width: 48rem) {
  .layout_jds-Layout__hFF0F {
    --Layout-size: var(--medium-breakpoint);
    --Layout-column-gutter-size: var(--spacer-300);

    flex-wrap: initial;
    gap: var(--Layout-column-gutter-size);
  }

  .layout_jds-Layout--isBo__hWLcI {
    --Layout-size: calc(var(--medium-breakpoint) - var(--jds-bo-width-delta, 0px));
  }

  .layout_jds-Layout--isFullWidth__Lkbb3 {
    --Layout-column-margin-size: var(--spacer-400);
  }

  .layout_jds-Layout--topPadding__vMye5:not(.layout_jds-Layout--isBo__hWLcI) {
    padding-top: var(--spacer-600);
  }

  .layout_jds-Layout--bottomPadding__sa1oV:not(.layout_jds-Layout--isBo__hWLcI) {
    padding-bottom: var(--spacer-600);
  }
}

@media (min-width: 64rem) {
  .layout_jds-Layout__hFF0F {
    --Layout-size: var(--large-breakpoint);
    --Layout-column-gutter-size: var(--spacer-300);
    --Layout-column-margin-size: var(--spacer-300);
  }

  .layout_jds-Layout--isBo__hWLcI {
    --Layout-size: calc(var(--large-breakpoint) - var(--jds-bo-width-delta, 0px));
  }

  .layout_jds-Layout--isFullWidth__Lkbb3 {
    --Layout-column-margin-size: var(--spacer-400);
  }

  .layout_jds-Layout--topPadding__vMye5:not(.layout_jds-Layout--isBo__hWLcI) {
    padding-top: var(--spacer-800);
  }

  .layout_jds-Layout--bottomPadding__sa1oV:not(.layout_jds-Layout--isBo__hWLcI) {
    padding-bottom: var(--spacer-800);
  }

  .layout_jds-Layout--isSmall__03qdy {
    --Layout-paddingIsSmall: calc(( var(--Layout-column-size) + var(--Layout-column-gutter-size) ) * 2);

    width: calc(var(--Layout-size) - var(--Layout-paddingIsSmall) * 2);
    padding-right: calc(var(--Layout-padding) + var(--Layout-paddingIsSmall));
    padding-left: calc(var(--Layout-padding) + var(--Layout-paddingIsSmall));
  }

  .layout_jds-Layout--isExtraSmall__Hf5k8 {
    --Layout-paddingIsExtraSmall: calc(( var(--Layout-column-size) + var(--Layout-column-gutter-size) ) * 3);

    width: calc(var(--Layout-size) - var(--Layout-paddingIsExtraSmall) * 2);
    padding-right: calc(var(--Layout-padding) + var(--Layout-paddingIsExtraSmall));
    padding-left: calc(var(--Layout-padding) + var(--Layout-paddingIsExtraSmall));
  }
}

@media (min-width: 80rem) {
  .layout_jds-Layout__hFF0F {
    --Layout-size: var(--major-breakpoint);
    --Layout-column-gutter-size: var(--spacer-400);
  }

  .layout_jds-Layout--isBo__hWLcI {
    --Layout-size: calc(var(--major-breakpoint) - var(--jds-bo-width-delta, 0px));
  }

  .layout_jds-Layout--isFullWidth__Lkbb3 {
    --Layout-column-margin-size: var(--spacer-500);
  }
}

@media (min-width: 90rem) {
  .layout_jds-Layout--isFullWidth__Lkbb3 {
    --Layout-size: var(--huge-breakpoint);
  }

  /* stylelint-disable-next-line plugin/selector-bem-pattern */
  .layout_jds-Layout--isFullWidth__Lkbb3.layout_jds-Layout--isBo__hWLcI {
    --Layout-size: calc(var(--huge-breakpoint) - var(--jds-bo-width-delta, 0px));
  }
}

@media (min-width: 120rem) {
  .layout_jds-Layout--isFullWidth__Lkbb3 {
    --Layout-size: var(--extra-breakpoint);

    max-width: var(--Layout-size);
    padding-right: var(--Layout-padding);
    padding-left: var(--Layout-padding);
  }

  /* stylelint-disable-next-line plugin/selector-bem-pattern */
  .layout_jds-Layout--isFullWidth__Lkbb3.layout_jds-Layout--isBo__hWLcI {
    --Layout-size: calc(var(--extra-breakpoint) - var(--jds-bo-width-delta, 0px));
  }
}

}
